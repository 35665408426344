<template>
  <div class="related-articles">
    <div v-for="key in relatedArticles" :key="key.id" class="related-article"
      :class="{ 'related-article--no-swiper': relatedArticles.length > 2 }" @click="goToArticle(key.id)">
      <picture class="related-article__image">
        <source :srcset="`${$root.globalUrl}/uploads/articles/${key.titlePictureMobile}`" media="(max-width: 900px)" />
        <img :src="`${$root.globalUrl}/uploads/articles/${key.titlePicture}`" alt="">
      </picture>

      <div class="related-article__body">
        <h3 class="related-article__title" v-html="key.tile"></h3>
        <!-- <h3 class="related-article__title" v-html="key.tile" :class="{ 'related-article__title--cut': key.description }"></h3> -->
        <!-- <p v-if="key.description" class="related-article__description" v-html="key.description"></p> -->
        <img class="related-article__arrow" src="@/assets/icons/arrow.svg" alt="arrow">
        <img class="related-article__arrow related-article__arrow--long" src="@/assets/icons/arrow_long.svg"
          alt="arrow">
      </div>
    </div>

    <swiper v-if="relatedArticles.length > 2" :options="sliderOptions" class="related-articles__swiper">
      <swiper-slide v-for="key in relatedArticles" :key="key.id" class="related-article">
        <div @click="goToArticle(key.id)">
          <picture class="related-article__image">
            <source :srcset="`${$root.globalUrl}/uploads/articles/${key.titlePictureMobile}`"
              media="(max-width: 900px)" />
            <img :src="`${$root.globalUrl}/uploads/articles/${key.titlePicture}`" alt="">
          </picture>

          <div class="related-article__body">
            <h3 class="related-article__title" v-html="key.tile"></h3>
            <!-- <h3 class="related-article__title" v-html="key.tile" :class="{ 'related-article__title--cut': key.description }"></h3> -->
            <!-- <p v-if="key.description" class="related-article__description" v-html="key.description"></p> -->
            <img class="related-article__arrow" src="@/assets/icons/arrow.svg" alt="arrow">
            <img class="related-article__arrow related-article__arrow--long" src="@/assets/icons/arrow_long.svg"
              alt="arrow">
          </div>
        </div>
      </swiper-slide>

      <div class="swiper-button swiper-button-prev hover_type7" slot="button-prev">
        <svg style="margin-right: 4px;" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 17L2 9.12513L10 1" stroke="#00A03B" stroke-width="2" stroke-linecap="round" />
        </svg>
      </div>

      <div class="swiper-button swiper-button-next hover_type7" slot="button-next">
        <svg style="margin-left: 4px;" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1L9 8.87488L0.999998 17" stroke="#00A03B" stroke-width="2" stroke-linecap="round" />
        </svg>
      </div>
    </swiper>
  </div>
</template>

<script>
  export default {
    name: 'RelatedArticle',

    components: {},

    props: {
      relatedArticles: {
        type: Array,
        required: true,
      },
    },

    data() {
      return {
        sliderOptions: {
          slidesPerView: 2,
          spaceBetween: 20,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          breakpoints: {
            540: {
              slidesPerView: 2,
              spaceBetween: 20
            },
            320: {
              slidesPerView: 1,
              spaceBetween: 10
            },
          }
        }
      };
    },

    methods: {
      goToArticle(articleId) {
        this.$router.push({ name: 'page', params: { id: articleId } });
      },
    },
  };
</script>

<style scoped lang="scss">
  @import "../../assets/style/fonts.css";
  @import "../../assets/style/vars";

  .related-articles {
    display: flex;
    justify-content: space-between;
    gap: 8px;

    @media screen and (max-width: 550px) {
      flex-direction: column;
    }

    &__swiper {
      padding-bottom: 60px;

      @media screen and (max-width: 550px) {
        display: none;
      }
    }
  }

  .related-article {
    $this: &;

    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    background-color: $green;
    max-width: calc(50% - 10px);
    width: 100%;
    transition: box-shadow 0.3s;

    @media screen and (max-width: 550px) {
      flex-direction: row;
    }

    &--no-swiper {
      display: none;

      @media screen and (max-width: 550px) {
        display: flex;
        margin-bottom: 8px;
      }
    }

    &:hover {
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.20);

      #{$this}__arrow {
        display: none;
      }

      #{$this}__arrow--long {
        display: block;
      }
    }

    &:active {
      opacity: .7;
    }

    &:nth-child(2n) {
      background-color: $teal;
    }

    @media screen and (max-width: 550px) {
      max-width: 100%;
    }

    &__image {
      max-width: 100%;
      width: 100%;
      max-height: 200px;
      min-height: 200px;
      height: 100%;
      display: block;

      img {
        min-width: 100%;
        min-height: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
      }

      @media screen and (max-width: 550px) {
        max-width: 120px;
        min-width: 120px;
        max-height: 120px;
        min-height: 120px;
        height: 120px;
      }
    }

    &__body {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100%;
      height: 170px;
      width: 100%;
      padding: 16px 20px 24px;

      @media screen and (max-width: 550px) {
        height: 120px;
      }
    }

    &__title {
      font-family: $B;
      font-size: 16px;
      color: white;
      margin-bottom: 5px;

      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;

      @media screen and (max-width: 550px) {
        -webkit-line-clamp: 4;
      }

      &--cut {
        -webkit-line-clamp: 2;
      }
    }

    &__description {
      font-family: $R;
      font-size: 14px;
      color: white;
      margin-bottom: 25px;
      padding-right: 20px;
      max-height: 34px;
      overflow: hidden;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__arrow {
      position: absolute;
      right: 10px;
      bottom: 10px;

      &--long {
        display: none;
      }
    }
  }

  .swiper-button {
    top: auto;
    bottom: 0;
    left: auto;

    height: 40px;
    width: 40px;

    display: flex;
    justify-content: center;
    align-items: center;

    border: 2px solid $green;
    border-radius: 50%;

    &:after {
      display: none;
    }

    svg {
      max-width: 11px;
      width: 100%;
      height: auto;
      display: block;
    }
  }

  .swiper-button-prev {
    right: 50px;
  }

  .swiper-button-next {
    right: 0px;
  }
</style>